import api from "./api.js";

export default {
  data: () => ({
    search: "",
    text: "",
    data: [],
    items: [],
    cat_list: [],
    // admin: true,
    // menu: false,
    dialog: false,
    id: null,
    name: null,
    parent: null,
    parent_id: null,
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    loading: true,
    myCurrentData: [],
    json_fields: {
      'Id': 'id',
      'FName': 'name',
      'Parent': 'parent',
  },
    headers: [
      {
        text: "ID",
        align: "center",
        value: "id",
      },
      {
        text: "Name",
        align: "center",
        value: "name",
      },
      {
        text: "Parent",
        align: "center",
        value: "parent",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),

  mounted() {
    api
      .getcategories()
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          res.data.data.forEach((element) => {
            this.items.push(element);
            if(element.parent_id == null){
               this.cat_list.push(element.name);
            }
          });
          this.loading = false;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
  },
    showDialog(item) {
      if (item == null) {
        this.selected = 0;
        this.dialog = true;
        this.id = null;
        this.name = null;
        this.parent = null;
        this.disable = false;
      } else {
        this.selected = 1;
        this.selected_item = item;
        this.dialog = true;
        this.id = item.id;
        this.name = item.name;
        this.parent = null;
        this.disable = false;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.selected == 0) {
          this.disable = true;
          api.save(this.id, this.name, this.parent, this.selected)
            .then((res) => {

              this.dialog = false;
              if (res.data.responseCode === 1) {
                // this.$router.go();
                this.items.push({
                  id: res.data.data.id,
                  name: res.data.data.name,
                  parent_id: res.data.data.parent_id,
                });
                this.snackbar = true;
                this.text = "Category added successfully!"
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;
              console.log(error);

            })

        } else {
          this.disable = true;
          api.save(this.id, this.name, this.parent, this.selected)
            .then((res) => {


              this.dialog = false;
              console.log("responseCode=" + res.data.responseCode);
              if (res.data.responseCode === 1) {
                // this.$router.go();
                var index = this.items.indexOf(this.selected_item);
                this.items[index].name = this.name;
                this.items[index].parent_id = this.parent_id;
                this.snackbar = true;
                this.text = "Category updated successfully!";
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, Please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;


            })

        }
      }
    },

    deleteDialog(item) {
      this.selected_item = item;
      this.deletedialog = true;
    },

    confirmOnDelete() {
      api.delete(this.selected_item.id).then((res) => {
        this.deletedialog = false;
        if (res.data.responseCode === 1) {
          // this.$router.go();
          var index = this.items.indexOf(this.selected_item);
          this.$delete(this.items, index);
          this.snackbar = true;
          this.text = "Category deleted!"
        } else {
          console.log(res.data.responseMessage);
          this.snackbar = true;
          this.text = "Try again!"
        }
      }).catch((error) => {
        // console.log(error);
        this.deletedialog = false;
        this.snackbar = true;
        this.text = error
      })
    },
  },
};
