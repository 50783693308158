import Vue from 'vue'

import * as VueGoogleMaps from 'vue2-google-maps'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import Mainlayout from "./layouts/Mainlayout.vue"
import Defaultlayout from "./layouts/Defaultlayout.vue"
import CustomerLayout from "./layouts/CustomerLayout.vue"
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import Vuex from 'vuex'

Vue.component('mainlayout', Mainlayout);
Vue.component('defaultlayout', Defaultlayout);
Vue.component('customerlayout', CustomerLayout);
Vue.config.productionTip = false;
import excel from 'vue-excel-export';
Vue.use(Vuex)
Vue.use(DatetimePicker)
Vue.use(excel)
Vue.prototype.$globalData = Vue.observable({
  show: true,
  basket: localStorage.getItem("basket") == null ? 0 : localStorage.getItem("basket"),
  basket_items: localStorage.getItem("basket_items") == null ? [] : JSON.parse(localStorage.getItem("basket_items")),
});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDIHW30Z5TLlNLgCf0UQeLt3lUFEO3yctc',
    libraries: 'places',
  },
},)
new Vue({
  router,
  store,
  Vuex,
  vuetify,
  render: h => h(App)
}).$mount('#app')
require('@/assets/css/style.css')