import axios from "@/plugins/http.service.js"

export default{
    savequotation(name, email, mobile, company, total){
        var data = {
            'name': name,
            'email': email, 
            'mobile': mobile, 
            'company': company,
            'total': total
        }
        return axios.post("quotation/save-quotation", data);
    },

    savequotationline(quotation_id, product_id, quantity, total){
        var data = {
            'quotation_id': quotation_id,
            'product_id': product_id,
            'quantity': quantity,
            'total': total
        }
        return axios.post("quotation-line/save-quotation-line", data);
    }
}