import api from "./api.js";

export default {
  data: () => ({
    id: '', 
    token: ''
  }),

  mounted() {
    this.$globalData.show = false;
    this.id = this.$route.params.id; 
    this.token = this.$route.params.token
    console.log(this.id)
  },
  methods: {
    verifyEmail() {
        api
          .verify(this.id, this.token)
          .then((res) => {
            if (res.data.responseCode === 1) {
                this.$router.push({ name: "Forgot Password" });
            } else {
              this.warn = true;
              console.log(res.data.responseMessage);
              this.text = res.data.responseMessage;
            }
          })
          .catch((error) => {
            this.warn = true;
            console.log("error :" + error);
          });
     
    },
  },
};