import axios from "@/plugins/http.service.js"

export default{
    getcategories(){
        return axios.post("category/get-categories");
    },

    save(id, name, parent, selected){
        var data={
            id: id,
            name: name,
            parent: parent,
            selected:selected,
        }
        return axios.post("category/save" , data);
    },

    delete(id){
        var data={
            id: id,
        }
        return axios.post("category/delete", data);
    },
}