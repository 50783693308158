import axios from "@/plugins/http.service.js"

export default{
    getproductsbycategory(name){
        var data = {
            'name': name
          };
        return axios.post("product/get-products-by-category",data);
    },

    getcategories(){
        return axios.post("category/get-categories");
    },

    getbrands(){
        return axios.post("brand/get-brands");
    },
}