import api from "./api.js";

export default {
  data: () => ({
    basket_items: [],
    number: null,
    selected_item: null,
    fname: null,
    lname: null,
    id: null,
    quotation: null,
    all: 0,
    name: null,
    email: null,
    mobile: null,
    company: null,
    emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    warning: false,
  }),

  mounted() {
    console.log(localStorage.getItem("basket_items"));
    this.basket_items = JSON.parse(localStorage.getItem("basket_items"));
    // console.log(this.basket_items);
    this.fname = localStorage.getItem("customer_fname");
    this.lname = localStorage.getItem("customer_lname");
    this.id = localStorage.getItem("admin_id");
    this.basket_items.forEach((element) => {
      this.all += element.total;
    });
  },

  methods: {
    submit() {
      if (
        this.name !== null &&
        this.email !== null &&
        this.mobile !== null &&
        this.company !== null
      ) {
        this.warning = false;
        api
          .savequotation(
            this.name,
            this.email,
            this.mobile,
            this.company,
            this.all
          )
          .then((res) => {
            if (res.data.responseCode === 1) {
              console.log("quotation added");
              this.quotation = res.data.data.id;
              this.basket_items.forEach((element) => {
                api
                  .savequotationline(
                    this.quotation,
                    element.id,
                    element.quantity,
                    element.total
                  )
                  .then((res) => {
                    if (res.data.responseCode === 1) {
                      console.log("quotation line added");
                      this.$router.push({ name: "Thankyou" });
                    } else {
                      console.log(res.data.responseMessage);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            } else {
              console.log(res.data.responseMessage);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        localStorage.setItem("basket", 0);
        localStorage.setItem("basket_items", null);
        this.$globalData.basket = 0;
        this.$globalData.basket_items = [];
      } else {
        this.warning = true;
      }
    },
    minus(item) {
      var count = item.quantity - 1;
      if (count >= 1) {
        item.total -= parseFloat(item.price);
        this.all -= parseFloat(item.price);
      }
      count = count < 1 ? 1 : count;
      item.quantity = count;

      return false;
    },

    plus(item) {
      item.quantity++;
      item.total += parseFloat(item.price);
      this.all += parseFloat(item.price);
      return false;
    },

    deletefrombasket(item) {
      // console.log(item);
      // console.log(this.basket_items)
      var index = this.basket_items.indexOf(item);
      // console.log(index);

      this.all -= item.total;
      this.$delete(this.basket_items, index);
      console.log(this.basket_items);
      localStorage.setItem("basket_items", JSON.stringify(this.basket_items));
      this.$globalData.basket_items = this.basket_items;
      console.log(localStorage.getItem("basket_items"));
      localStorage.setItem("basket", localStorage.getItem("basket") - 1);
      this.$globalData.basket--;
    },
  },
};
