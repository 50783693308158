import axios from "@/plugins/http.service.js";

export default {
  changePassword2(id, password) {
    var data = {
        'id': id,
        'password': password, 
    };
    return axios.post("customer/change-password-two", data);
  },
};
