import api from "./api.js";

export default{
    data: () => ({
        excelfile: null,
      }),

    methods: {
        saveExcel() {
            let namef = this.excelfile.name;
            console.log(namef);
            let ext = namef.split(".");
            console.log(ext);
            if (ext[1] == "csv") {
              let form = new FormData();
              form.append("filename", this.excelfile);
              api
                .saveExcel(form)
                .then((res) => {
                  if (res.data.responseCode === 1) {
                      console.log("done");
                      this.excelfile = null;
                      alert("File Imported successfully!");
                  } else {
                    this.error = res.data.responseMessage;
                    this.errorDialog = true;
                  }
                })
                .catch((error) => {
                    console.log(error)
                });
            } else {
              console.log("you should upload a csv file");
            }
          },
    },
}