import axios from "@/plugins/http.service.js"

export default{
    getproductsbytype(type, name){
        var data = {
            'type': type,
            'name': name
          };
        return axios.post("product/get-products-by-type",data);
    },

    getcategories(){
        return axios.post("category/get-categories");
    },

    getbrands(){
        return axios.post("brand/get-brands");
    },
}