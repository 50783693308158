<!-- MainLayout -->
<template>
  <div id="parentx">
    <v-app id="inspire">
        <v-navigation-drawer
          v-model="drawer"
          :right="toright"
          :mini-variant="!mini"
          color="#f5e9dc"
          app
          mobile-breakpoint="1024"
          fixed
          :mini-variant-width="minWidth"
          floating
          height="100vh"
          style="box-shadow: rgb(2 2 2 / 18%) 2px -1px 6px 0px;"
        >
          <v-toolbar flat text class="tool">
            <div v-if="mobile==true">
              <v-list>
                <v-list-item justify-space-around>
                  <v-list-item-action @click="drawer = false" style="margin: auto">
                  <v-icon>close</v-icon>
                  </v-list-item-action>
                  </v-list-item>
              </v-list>

            </div>
            <div v-else>
            <v-list style="top: 50px" class="pa-0">
              <v-list-item style="top: 10px; left: -7px;">
                <v-list-item-action v-if="show">
                  <img
                    style="max-height: 100%; max-width: 170%"
                    src="../assets/images/favicon.svg"
                  />
                </v-list-item-action>
                <v-list-item-icon v-if="loggedin" style="margin: 0">
                  <router-link class="" to="/">
                    <div class="mainlogocontainer">
                      <img
                        style="
                         width: 150px;
                        "
                        alt="Vue logo"
                        class="logo"
                        src="../assets/images/coffeedesk.png"
                      />
                    </div>
                  </router-link>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            </div>
          </v-toolbar>
          <v-list  class="pt-0" color="#f5e9dc" style="position: relative; height: -webkit-fill-available; overflow-y: hidden; height: 90vh">
            <div v-if="admin == 'true'">
              <div :key="index" v-for="(item, index) in items">
                <v-list-item
                  exact-active-class="selectedrouter"
                  v-if="!item.children"
                  :key="item.title"
                  :to="item.to"
                  class="sidebarmenu"
                  justify-center
                >
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="alleft">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </div>
            </div> 
            <v-list-item class="logout-btn" @click="logout">
              <v-list-item-action>
                <v-icon>logout</v-icon>
              </v-list-item-action>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
            <v-spacer></v-spacer>
            <div v-if="mobile==false">
            <v-container color="#f2f4f5">
              <v-layout
                style=""
                flat
                column
              >
              <div v-if="mini">
                <v-btn
                  style="position: absolute; bottom: 5%; left:35%"
                  class="nohover"
                  color="#21507f"
                  text
                  @click.stop="mini = !mini"
                  @click="show = !show"
                >
                  
                    <img
                      class="titleimg"
                      :style="secondstyle"
                      alt="Vue logo"
                      src="../assets/images/expandi.svg"
                    />
                </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                  style="position: absolute; bottom: 5%; left:0 "
                  class="nohover"
                  color="#21507f"
                  text
                  @click.stop="mini = !mini"
                  @click="show = !show"
                >
                    <img
                      :style="firststyle"
                      class="titleimg"
                      alt="Vue logo"
                      src="../assets/images/expandi.svg"
                    />
                    </v-btn>
                  </div>
                
              </v-layout>
            </v-container>
            </div>
          </v-list>
        </v-navigation-drawer>
      <!-- </div> -->
      <v-main>
        <v-container fluid class="title_font" pa-0>
          <v-toolbar flat height="45px" color="#f5e9dc">
            <div v-if="mobile==true"> 
            <v-app-bar-nav-icon justify-start @click="drawer = !drawer"></v-app-bar-nav-icon>
            </div>
            <v-spacer></v-spacer>
          </v-toolbar>
          <router-view :key="$route.fullPath"></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  name: "mainlayout",
  data: () => ({
    admin: true,
    drawer: false,
    mini: true,
    toright: false,
    // fullname: "Admin",
    loggedin: false,
    items: [],
    firststyle: "",
    secondstyle: "transform: scaleX(-1);filter: FlipH; ;",
    minWidth: 80,
    show: false,
    mobile: false,
    // value: "holidays",
  }),

  mounted() {
    this.admin = localStorage.getItem("admin");
    // console.log(this.admin);
    console.log("mobile: " + this.mobile);
    this.onResize(null);

    this.loggedin = true;

    this.items = [
      {
        title: "Customers",
        icon: "mdi-account-group",
        to: "/customers",
      },
      {
        title: "Products",
        icon: "mdi-package-variant",
        to: "/products",
      },
      {
        title: "Categories",
        icon: "mdi-shape",
        to: "/categories",
      },
      {
        title: "Brands",
        icon: "mdi-tag-multiple",
        to: "/brands",
      },
       {
        title: "Orders",
        icon: "mdi-mailbox",
        to: "/orders",
      },
      {
        title: "Ads",
        icon: "mdi-advertisements",
        to: "/ads",
      },
      {
        title: "Import CSV",
        icon: "mdi-import",
        to: "/import",
      },
     
    ];

  },

  created() {
    // this.admin = localStorage.getItem("admin");
    // document.addEventListener("resize", this.onResize);
    this.onResize();
  },
  destroyed() {
    document.removeEventListener("resize", this.onResize);
  },
  methods: {
    logout: function () {
      localStorage.removeItem("admin_id");
      localStorage.removeItem("admin_email");
      localStorage.removeItem("admin");
      this.$router.push({ name: "Login" });
    },

    onResize() {
      if (window.innerWidth < 1024) {
        // this.minWidth = "0";
        this.mobile = true;
      } else {
        this.minWidth = "80";
        this.drawer = true;
        this.mobile=false;
      }
    },

    // gotovue(){
    //   switch(this.value){
    //     case 'holidays': this.$router.push({ name: "Login" });
    //     break;
    //     case 'employees': this.$router.push({ name: "Employees" });
    //     break;
      
    //   }
    // }
  },
};
</script>
<style>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 16px !important;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 16px !important;
}
.v-btn--fab.v-size--default .v-icon, .v-btn--fab.v-size--small .v-icon, .v-btn--icon.v-size--default .v-icon, .v-btn--icon.v-size--small .v-icon{
  font-size: 30px !important;
}
</style>