import axios from "@/plugins/http.service.js"

export default{
    getads(){
        return axios.post("ad/get-ads");
    },

    save(data){
        return axios.post("ad/save" , data);
    },

    delete(id){
        var data={
            id: id,
        }
        return axios.post("ad/delete", data);
    },
}