import api from "./api.js";

export default {
  data: () => ({
    data: [],
    items: [],
    cat_list: [],
    brand_list: [],
    ads: [],
    header_cat: [],
    dialog: false,
    title: null,
    description: null,
    price: 0,
    picture: null,
    label: null,
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    number: 1,
    basket: 0,
    text: "",
    page: 1,
    paginated_winner: [],
    pagination_length: 1,
    brands_pictures: [],
  }),

  mounted() {
    this.$globalData.show = true;
    api
      .getproducts()
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          res.data.data.forEach((element) => {
            this.items.push({
              id: element.id,
              name: element.name,
              picture: element.picture,
              description: element.description,
              price: element.price,
              number: this.number,
              label: element.label,
            });
          });
          this.$globalData.show = false;
          var num = this.items.length / 9;
          if (num % 2 != 0) {
            num = parseInt(num) + 1;
          }
          this.pagination_length = num;

          this.pagination();
          // console.log(this.items)
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getbrands()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            if (element.picture !== "") {
              if (element.featured == 1){
                this.brands_pictures.push(element);
              }
            }
            this.brand_list.push(element.name);
          });
        } else {
          console.log(res.data.responseMessage);
        }
        console.log(this.brands_pictures);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getcategories()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            // console.log(element);
            this.cat_list.push({
              id: element.id,
              name: element.name,
              parent: element.parent_id,
            });
            if (element.parent_id == null) {
              this.header_cat.push({
                id: element.id,
                name: element.name,
              });
            }
          });
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getads()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            // console.log(element);
            this.ads.push(element.picture);
          });
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    showdialog(item) {
      this.selected_item = item;
      this.dialog = true;
      this.picture = item.picture;
      this.title = item.name;
      this.description = item.description;
      this.number = item.number;
      this.price = item.price;
      this.label = item.label;
    },
    close() {
      this.dialog = false;
    },
    addtobasket(item) {
      // // this.selected_item = item;
      var item_one = {
        id: item.id,
        name: item.name,
        quantity: item.number,
        price: item.price,
        total: item.number * item.price,
      };
      let contain = true;
      // console.log(localStorage.getItem('basket_items'))
      // console.log(this.$globalData.basket_items);
      // console.log(item_one);
      if (this.$globalData.basket_items != null)
        this.$globalData.basket_items.forEach((item) => {
          if (item.id == item_one.id) {
            contain = false;
          }
        });
      if (contain) {
        this.basket = this.basket + 1;
        this.$globalData.basket++;
        this.$globalData.basket_items.push({
          id: item.id,
          name: item.name,
          quantity: item.number,
          price: item.price,
          total: item.number * item.price,
        });
        localStorage.setItem(
          "basket_items",
          JSON.stringify(this.$globalData.basket_items)
        );
        localStorage.setItem("basket", this.$globalData.basket);
        // console.log(localStorage.getItem("basket"));
        // console.log(localStorage.getItem("basket_items"));
        this.snackbar = true;
        this.text = "Product successfully added to your basket!";
      } else {
        this.snackbar = true;
        this.text = "Product is already in your basket!";
      }
      // this.selected_item = null;
    },

    minus(item) {
      var count = item.number - 1;
      count = count < 1 ? 1 : count;
      item.number = count;
      return false;
    },

    plus(item) {
      item.number++;
      return false;
    },

    minus_one() {
      var count = this.number - 1;
      count = count < 1 ? 1 : count;
      this.number = count;
    },

    plus_one() {
      this.number++;
    },

    addtobasketone() {
      console.log("11");
      this.basket = this.basket + 1;
      this.$globalData.basket++;
      this.$globalData.basket_items.push({
        id: this.selected_item.id,
        name: this.selected_item.name,
        quantity: this.number,
        price: this.number * this.selected_item.price,
      });
      localStorage.setItem(
        "basket_items",
        JSON.stringify(this.$globalData.basket_items)
      );
      localStorage.setItem("basket", this.$globalData.basket);
      console.log(localStorage.getItem("basket"));
      console.log(localStorage.getItem("basket_items"));
      this.snackbar = true;
      this.text = "Product successfully added to your basket!";
    },
    pagination() {
      this.paginated_winner = [];
      this.items.forEach((element) => {
        if (element.id <= 9 * this.page && element.id > 9 * (this.page - 1))
          this.paginated_winner.push(element);
      });
    },
  },
};
