import api from "./api.js";

export default {
  data: () => ({
    id: '',
    token: '',
    password: "",
    confirm_password: "",
    email: "",
    valid: true,
    showpass: false,
    showconfirm: false,
    emailRules: [v => /.+@.+/.test(v) || 'Invalid Email address'],
    passRules: [v => !!v || 'Password is required'],

  }),
  mounted() {
    this.$globalData.show = false;
   this.id = localStorage.getItem('admin_id')
  },
  methods: {
    changePassword2() {
      if (this.$refs.form.validate()) {
        api
            .changePassword2(this.id, this.password)
            .then((res) => {
              if (res.data.responseCode === 1) {
                  this.$router.push({ name: "Home" });
              } else {
                this.warn = true;
                this.text = res.data.responseMessage;
              }
            })
            .catch((error) => {
              this.warn = true;
              console.log("error :" + error);
            });
        } else {
          console.log("not cache");
        }
    },

    matchingPasswords() {
        if (this.password === this.confirm_password) {
          return true;
        } else {
          return 'Passwords does not match.';
        }
    }
  },
};