import Vue from "vue";
import VueRouter from "vue-router";
import Customers from "../views/admin/customers/index.vue";
import Login from "../views/front/login/index.vue";
import Register from "../views/front/register/index.vue";
import Products from "../views/admin/products/index.vue";
import Email from "../views/front/email/index.vue";
import Verify from "../views/front/verify-email/index.vue";
import VerifyPassword from "../views/front/verify-password/index.vue";
import ForgotPassword from "../views/front/forgot-password/index.vue";
import Categories from "../views/admin/categories/index.vue";
import Brands from "../views/admin/brands/index.vue";
import Ads from "../views/admin/ads/index.vue";
import Home from "../views/user/home/index.vue";
import MyOrders from "../views/user/my-orders/index.vue";
import ChangePassword from "../views/user/change-password/index.vue";
import Basket from "../views/user/basket/index.vue";
import Thankyou from "../views/user/thankyou/index.vue";
import BrandsProducts from "../views/user/productsbytype/index.vue";
import CategoryPage from "../views/user/categorypage/index.vue";
import Import from "../views/admin/import/index.vue";
import Orders from "../views/admin/orders/index.vue";
import OrderLines from "../views/admin/orderlines/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    beforeEnter: function(to, from, next) {
      next("/");
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { lay: "customerlayout" },
  },
  {
    path: "/admin",
    name: "Login",
    component: Login,
    meta: { lay: "defaultlayout" },
    beforeEnter: function(to, from, next) {
       if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "true"
      )
        next("/customers");
      else next();
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { lay: "defaultlayout" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "false"
      )
        next("/home");
      else if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "true"
      )
        next("/customers");
      else next();
    },
  },
  {
    path: "/forgot-password/:id/:token",
    name: "Forgot Password",
    component: ForgotPassword,
    meta: { lay: "defaultlayout" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "false"
      )
        next("/home");
      else if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "true"
      )
        next("/customers");
      else next();
    },
  },
  {
    path: "/email",
    name: "Email",
    component: Email,
    meta: { lay: "defaultlayout" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "false"
      )
        next("/home");
      else if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "true"
      )
        next("/customers");
      else next();
    },
  },
  {
    path: "/verify/:id/:token",
    name: "Verify",
    component: Verify,
    meta: { lay: "defaultlayout" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "false"
      )
        next("/home");
      else if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "true"
      )
        next("/customers");
      else next();
    },
  },
  {
    path: "/verify-password/:id/:token",
    name: "Verify Password",
    component: VerifyPassword,
    meta: { lay: "defaultlayout" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "false"
      )
        next("/home");
      else if (
        localStorage.getItem("admin_id") != null &&
        localStorage.getItem("admin") == "true"
      )
        next("/customers");
      else next();
    },
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    meta: { title: "Customers" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: { title: "Products" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    meta: { title: "Categories" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/brands",
    name: "Brands",
    component: Brands,
    meta: { title: "Brands" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/ads",
    name: "Ads",
    component: Ads,
    meta: { title: "Ads" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/basket",
    name: "Basket",
    component: Basket,
    meta: { lay: "customerlayout" },
    // beforeEnter: function(to, from, next) {
    //   if (
    //     localStorage.getItem("admin_id") == null ||
    //     localStorage.getItem("admin") == "true"
    //   )
    //     next("/login");
    //   else next();
    // },
  },
  {
    path: "/my-orders",
    name: "My Orders",
    component: MyOrders,
    meta: { lay: "customerlayout" },
    // beforeEnter: function(to, from, next) {
    //   if (
    //     localStorage.getItem("admin_id") == null ||
    //     localStorage.getItem("admin") == "true"
    //   )
    //     next("/login");
    //   else next();
    // },
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
    meta: { lay: "customerlayout" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "true"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: Thankyou,
    meta: { lay: "customerlayout" },
    // beforeEnter: function(to, from, next) {
    //   if (
    //     localStorage.getItem("admin_id") == null ||
    //     localStorage.getItem("admin") == "true"
    //   )
    //     next("/login");
    //   else next();
    // },
  },
  {
    path: "/products/:type/:name",
    name: "Productsbybrands",
    component: BrandsProducts,
    meta: { lay: "customerlayout" },
    // beforeEnter: function(to, from, next) {
    //   if (
    //     localStorage.getItem("admin_id") == null ||
    //     localStorage.getItem("admin") == "true"
    //   )
    //     next("/login");
    //   else next();
    // },
  },
  {
    path: "/category/:name",
    name: "CategoryPage",
    component: CategoryPage,
    meta: { lay: "customerlayout" },
    // beforeEnter: function(to, from, next) {
    //   if (
    //     localStorage.getItem("admin_id") == null ||
    //     localStorage.getItem("admin") == "true"
    //   )
    //     next("/login");
    //   else next();
    // },
  },
  {
    path: "/import",
    name: "Import CSV",
    component: Import,
    meta: { title: "Import CSV" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: { title: "Orders" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
  {
    path: "/orderlines/:customer/:order/:date",
    name: "OrderLines",
    component: OrderLines,
    meta: { title: "Order Details" },
    beforeEnter: function(to, from, next) {
      if (
        localStorage.getItem("admin_id") == null ||
        localStorage.getItem("admin") == "false"
      )
        next("/login");
      else next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
