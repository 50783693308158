<template>
  <div id="parenty" style="height:100%;background: #f2f4f5;">
    <v-progress-linear
      :active="$globalData.show"
      slot="progress"
      color="#ff8593"
      indeterminate
    ></v-progress-linear>
    <v-container style="height:100%">
      <router-view> </router-view>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "defaultlayout",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
