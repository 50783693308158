import api from "./api.js";

export default {
  data: () => ({
    search: "",
    text: "",
    data: [],
    items: [],
    // admin: true,
    // menu: false,
    dialog: false,
    id: null,
    fname: null,
    lname: null,
    email: null,
    mobile: null,
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    tokendialog: false,
    token: null,
    loading: true,
    myCurrentData: [],
    json_fields: {
      'Id': 'id',
      'Full Name': 'first_name',
      'Last Name': 'last_name',
      'E-mail': 'email',
      'Mobile': 'mobile',
  },
    headers: [
      {
        text: "ID",
        align: "center",
        value: "id",
      },
      {
        text: "First Name",
        align: "center",
        value: "first_name",
      },
      {
        text: "Last Name",
        align: "center",
        value: "last_name",
      },

      {
        text: "E-mail",
        align: "center",
        value: "email",
      },
      {
        text: "Mobile",
        align: "center",
        value: "mobile",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),
  mounted() {
    api
      .getcustomers()
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.loading = false;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
  },
    showDialog(item) {
      if (item == null) {
        this.selected = 0;
        this.dialog = true;
        this.id = null;
        this.fname = null;
        this.lname = null;
        this.email = null;
        this.mobile = null;
        this.disable = false;
      } else {
        this.selected = 1;
        this.selected_item = item;
        this.dialog = true;
        this.id = item.id;
        this.fname = item.first_name;
        this.lname = item.last_name;
        this.email = item.email;
        this.mobile = item.mobile;
        this.disable = false;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.selected == 0) {
          this.disable = true;
          
          api.save(this.id, this.fname, this.lname, this.email, this.mobile, this.selected)
            .then((res) => {
              this.dialog = false;
              if (res.data.responseCode === 1) {
                // this.$router.go();
                this.items.push({
                  id: res.data.data.id,
                  first_name: res.data.data.first_name,
                  last_name: res.data.data.last_name,
                  email: res.data.data.email,
                  mobile: res.data.data.mobile,
                });
                this.snackbar = true;
                this.text = "Customer added successfully!"
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;
              console.log(error);

            })

        } else {
          this.disable = true;
          api.save(this.id, this.fname, this.lname, this.email, this.mobile, this.selected)
            .then((res) => {


              this.dialog = false;
              console.log("responseCode=" + res.data.responseCode);
              if (res.data.responseCode === 1) {
                // this.$router.go();
                var index = this.items.indexOf(this.selected_item);
                this.items[index].fname = this.fname;
                this.items[index].lname = this.lname;
                this.items[index].email = this.email;
                this.items[index].mobile = this.mobile;
                this.snackbar = true;
                this.text = "Customer updated successfully!";
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, Please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;


            })

        }
      }
    },

    deleteDialog(item) {
      this.selected_item = item;
      this.deletedialog = true;
    },

    confirmOnDelete() {
      api.delete(this.selected_item.id).then((res) => {
        this.deletedialog = false;
        if (res.data.responseCode === 1) {
          // this.$router.go();
          var index = this.items.indexOf(this.selected_item);
          this.$delete(this.items, index);
          this.snackbar = true;
          this.text = "Customer deleted!"
        } else {
          console.log(res.data.responseMessage);
          this.snackbar = true;
          this.text = "Try again!"
        }
      }).catch((error) => {
        // console.log(error);
        this.deletedialog = false;
        this.snackbar = true;
        this.text = error
      })
    },

    tokenGeneratorDialog(item) {
      this.selected_item = item;
      this.tokendialog = true;
      this.disable = false;
      this.token = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
    },

    saveToken() {
      api.savetoken(this.selected_item.id, this.token).then((res) => {
        if (res.data.responseCode === 1) {
          this.tokendialog = false;
          this.snackbar = true;
          this.text = "Token Assigned";
        } else {
          console.log(res.data.responseMessage);
          this.snackbar = true;
          this.text = "Try again!"
        }

      }).catch((error) => {
        this.tokendialog = false;
        this.snackbar = true;
        this.text = error;
      })
    }
  },
};
