<template>
  <v-app>
    <component  v-if="layout" :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.lay || "mainlayout";
    },
  },

  watch: {
      '$route' () {
        document.title = 'Coffee Desk';
    },
  }
};
</script>

