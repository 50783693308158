import api from "./api.js";

export default {
  data: () => ({
    search: "",
    text: "",
    data: [],
    items: [],
    cat_list: [],
    // admin: true,
    // menu: false,
    dialog: false,
    picture: null,
    id: null,
    name: null,
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    loading: true,
    newpic: null,
    options: [],
    input: false,
    featured: null,
    myCurrentData: [],
    json_fields: {
      'Id': 'id',
      'FName': 'name',
      'Featured': 'featured',
    },
    headers: [
      {
        text: "Brand",
        align: "center",
        value: "picture",
      },
      {
        text: "ID",
        align: "center",
        value: "id",
      },
      {
        text: "Name",
        align: "center",
        value: "name",
      },
      {
        text: "Featured",
        align: "center",
        value: "featured",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),

  mounted() {
    // this.admin = localStorage.getItem("admin");
    // console.log(this.admin);
    this.options = [{ value: 1, text: 'Yes' }, { value: 0, text: 'No' },];
    api
      .getbrands()
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.loading = false;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    showDialog(item) {
      if (item == null) {
        this.selected = 0;
        this.dialog = true;
        this.id = null;
        this.picture = null;
        this.name = null;
        this.featured = 0;
        this.disable = false;
      } else {
        this.selected = 1;
        this.selected_item = item;
        this.dialog = true;
        this.id = item.id;
        this.picture = item.picture;
        this.name = item.name;
        this.disable = false;
        this.featured = item.featured;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.picture == null) {
          this.snackbar = true;
          this.text = "Please add a picture";
          return;
        }
        if (this.selected == 0) {
          this.disable = true;
          let formData = new FormData();
          formData.append("id", this.id);
          formData.append("name", this.name);
          formData.append("featured", this.featured);
          formData.append("picture", this.picture);
          formData.append("selected", this.selected);

          api.save(formData)
            .then((res) => {

              this.dialog = false;
              if (res.data.responseCode === 1) {
                this.$router.go();
                this.items.push({
                  id: res.data.data.id,
                  name: res.data.data.name,
                  featured: res.data.data.featured,
                  picture: res.data.data.picture,
                });
                this.snackbar = true;
                this.text = "Brand added successfully!"
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;
              console.log(error);

            })

        } else {
          this.disable = true;

          let formData2 = new FormData();
          formData2.append("id", this.id);
          formData2.append("name", this.name);
          formData2.append("selected", this.selected);
          formData2.append("featured", this.featured);
          if (this.input == true) {
            formData2.append("picture", this.newpic);
          } else {
            formData2.append("picture", null);
          }

          api.save(formData2)
            .then((res) => {
              this.dialog = false;
              console.log("responseCode=" + res.data.responseCode);
              if (res.data.responseCode === 1) {
                this.$router.go();
                var index = this.items.indexOf(this.selected_item);
                this.items[index].name = this.name;
                this.items[index].picture = this.newpic;
                this.items[index].featured = this.featured;
                this.snackbar = true;
                this.text = "Brand updated successfully!";
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, Please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;


            })

        }
      }
    },

    showinput() {
      this.input = true;
      document.getElementById("img").style.display = "none";
      document.getElementById("img-icon").style.display = "none";
    },

    deleteDialog(item) {
      this.selected_item = item;
      this.deletedialog = true;
    },

    confirmOnDelete() {
      api.delete(this.selected_item.id).then((res) => {
        this.deletedialog = false;
        if (res.data.responseCode === 1) {
          // this.$router.go();
          var index = this.items.indexOf(this.selected_item);
          this.$delete(this.items, index);
          this.snackbar = true;
          this.text = "Brand deleted!"
        } else {
          console.log(res.data.responseMessage);
          this.snackbar = true;
          this.text = "Try again!"
        }
      }).catch((error) => {
        // console.log(error);
        this.deletedialog = false;
        this.snackbar = true;
        this.text = error
      })
    },
  },
};
