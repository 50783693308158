import api from "./api.js";

export default {
  data: () => ({
    search: "",
    text: "",
    data: [],
    items: [],
    datalaoded: 0,
    id: null,
    name: null,
    price: null,
    description: null,
    total: 0,
    category: null,
    loading: true,
    selected_item: null,
    myCurrentData: [],
    json_fields: {
      Id: "id",
      Customer: "customer",
      Total: "total",
      "Submitted Date": "submitted_date",
    },
    headers: [
      {
        text: "ID",
        align: "center",
        value: "id",
      },
      {
        text: "Customer",
        align: "center",
        value: "customer",
      },
      {
        text: "Total",
        align: "center",
        value: "total",
      },
      {
        text: "Submitted Date",
        align: "center",
        value: "submitted_date",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),
  mounted() {
    api
      .getorders()
      .then((res) => {
        // console.log(res.data.data);
        if (res.data.responseCode === 1) {
          // console.log(res.data.responseCode);
          this.items = res.data.data;
          this.loading = false;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    orderlines(item) {
      this.selected_item = item;
      this.$router.push({
        name: "OrderLines",
        params: {
          order: item.id,
          customer: item.customer_name + " " + item.customer_lastname,
          date: item.submitted_date,
        },
      });
    },
  },
};
