import axios from "@/plugins/http.service.js"

export default{
    getcustomers(){
        return axios.post("customer/get-customers");
    },

    save(id, fname, lname, email, mobile, selected){
        var data={
            id: id,
            fname: fname,
            lname: lname,
            email: email,
            mobile: mobile,
            selected:selected,
        }
        return axios.post("customer/save" , data);
    },

    delete(id){
        var data={
            id: id,
        }
        return axios.post("customer/delete", data);
    },

    savetoken(id, token){
        var data={
            id: id,
            token: token,
        }
        return axios.post("customer/save-token", data);
    }
}