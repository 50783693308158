var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")])]),_c('v-spacer'),_c('div',{staticClass:"btn"},[_c('v-btn',{staticClass:"formbuttons",on:{"click":function($event){return _vm.showDialog(null)}}},[_vm._v(" Add ")])],1),_c('export-excel',{staticClass:"btn",attrs:{"data":_vm.myCurrentData,"fields":_vm.json_fields,"worksheet":"Brands","name":"Brands.xls"}},[_c('v-btn',{staticClass:"formbuttons",staticStyle:{"margin-left":"10px"}},[_vm._v(" Export ")])],1)],1),_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{attrs:{"src":'api/web/uploads/brands/' +
            item.picture,"width":"50px"}})])]}},{key:"item.featured",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.featured === 1 ? 'Yes' : 'No')+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor",attrs:{"color":"var(--secondary)"},on:{"click":function($event){return _vm.showDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" edit")])]}}],null,true)},[_c('span',[_vm._v(" Edit ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"delete-icon",attrs:{"color":"var(--secondary)"},on:{"click":function($event){return _vm.deleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.selected == 0 ? "Add" : "Update")+" a customer ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"id","disabled":""},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}}),_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(_vm.selected == 0)?_c('div',[_c('v-file-input',{attrs:{"chips":"","label":"Picture","accept":"image/*"},model:{value:(_vm.picture),callback:function ($$v) {_vm.picture=$$v},expression:"picture"}})],1):_c('div',[_c('img',{staticStyle:{"position":"relative"},attrs:{"src":'api/web/uploads/brands/' +
                    _vm.picture,"width":"100px","id":"img"}}),_c('v-icon',{staticStyle:{"position":"absolute"},attrs:{"id":"img-icon"},on:{"click":_vm.showinput}},[_vm._v(" mdi-close ")]),[(_vm.input == true)?_c('div',[_c('v-file-input',{attrs:{"chips":"","label":"Picture","accept":"image/*"},model:{value:(_vm.newpic),callback:function ($$v) {_vm.newpic=$$v},expression:"newpic"}})],1):_vm._e()]],2),_c('v-select',{attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Featured?"},model:{value:(_vm.featured),callback:function ($$v) {_vm.featured=$$v},expression:"featured"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#ff8593","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"#ff8593","text":"","disabled":_vm.disable == true},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deletedialog),callback:function ($$v) {_vm.deletedialog=$$v},expression:"deletedialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirmation Required ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete this brand? This action cannot be undone!")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#ff8593","text":""},on:{"click":function($event){_vm.deletedialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"#ff8593","text":""},on:{"click":_vm.confirmOnDelete}},[_vm._v(" Confirm ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"#ff8593","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }