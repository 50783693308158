import api from "./api.js";

export default {
  data: () => ({
    data: [],
    items: [],
    cat_list: [],
    brand_list: [],
    type: null,
    name: null,
    header_cat: [],
    dialog: false,
    title: null,
    description: null,
    price: 0,
    label: null,
    picture: null,
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    number: 1,
    basket: 0,
    text: "",
    show: true
    }),


  mounted() {
    this.$globalData.show = true;
    this.name = this.$route.params.name;
    this.type = this.$route.params.type;
    console.log(this.type)
    api
      .getproductsbytype(this.type, this.name)
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          res.data.data.forEach((element) => {
            this.items.push({
              id: element.id,
              name: element.name,
              picture: element.picture,
              description: element.description,
              price: element.price,
              number: this.number,
              label: element.label
            });
          });
          this.$globalData.show = false;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api.getbrands().then((res) => {
      if(res.data.responseCode === 1) {
        res.data.data.forEach((element) => {
          this.brand_list.push(element.name);
        })
      }else{
        console.log(res.data.responseMessage);
      }
    }).catch((error) => {
      console.log(error)
    });

    api.getcategories().then((res) => {
      if(res.data.responseCode === 1) {
        res.data.data.forEach((element) => {
          this.cat_list.push({
            id: element.id,
            name: element.name,
            parent: element.parent_id
          });
          if(element.parent_id == null){
              this.header_cat.push({
                id: element.id,
                name: element.name,
              });
          }
        })
      }else{
        console.log(res.data.responseMessage);
      }
    }).catch((error) => {
      console.log(error)
    }); 
  },
  methods: {
    close(){
      this.dialog = false;
    },
    
    showdialog(item){
      this.selected_item = item;
      this.dialog = true
      this.picture = item.picture;
      this.title = item.name;
      this.description = item.description;
      this.price = item.price
      this.label = item.label
    },

    addtobasket(item){
      this.basket = this.basket + 1;
      this.$globalData.basket++;
      this.$globalData.basket_items.push({
        id: item.id,
        name: item.name,
        quantity: item.number,
        price: item.price, 
        total: item.number * item.price
      });
      localStorage.setItem("basket_items",JSON.stringify(this.$globalData.basket_items));
      localStorage.setItem("basket", this.$globalData.basket);
      console.log(localStorage.getItem("basket"));
      console.log(localStorage.getItem("basket_items"));
      this.snackbar = true;
      this.text = "Product successfully added to your basket!";
    },

    minus(item) {
      var count = item.number-1;
      count = count < 1 ? 1 : count;
      item.number = count;
      return false;
    },

    plus(item) {
      item.number++;
      return false;
    },

    update(index, value) {
      let basket_items = JSON.parse(localStorage.getItem("basket_items"));
      basket_items[index].quantity = value;
      localStorage.setItem("basket_items", JSON.stringify(basket_items));
      this.myItem = JSON.parse(localStorage.getItem("basket_items"));
    },
  }

};
