import axios from "@/plugins/http.service.js";

export default {
  register(first_name, last_name, email, pass, mobile, token, verified) {
    var data = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'password': pass, 
        'mobile': mobile,
        'token': token, 
        'verified': verified
    };
    // console.log("data= "+ data.email + " " + data.password);
    return axios.post("customer/register", data);
  },
};
