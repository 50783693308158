import api from "./api.js";

export default {
  data: () => ({
    show: true,
    email: "",
    mobile: "",
    password: "",
    first_name: "",
    last_name: "",
    confirm_password: "",
    token: "",
    valid: true,
    showpass: false,
    showconfirm: false,
    rules: [(v) => !!v || "This field is required"],
    emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    passRules: [(v) => !!v || "Password is required"],
    text: '',
    snackbar: false,
    timeout: 3000,
    disabled: false
  }),
  mounted() {
    this.$globalData.show = false;
  },
  methods: {
    register() {
      this.$globalData.show = true;
      this.disabled = true;
      if (this.$refs.form.validate()) {
        this.token =
          Math.random()
            .toString(36)
            .substring(2, 6) +
          Math.random()
            .toString(36)
            .substring(2, 6);
        api
          .register(
            this.first_name,
            this.last_name,
            this.email,
            this.password,
            this.mobile,
            this.token,
            1
          )
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.$globalData.show = false;
              this.disabled = false;
              this.snackbar = true;
              this.text = "Please check your email!"
            } else {
              this.$globalData.show = false;
              this.disabled = false;
              this.snackbar = true;
              this.text = res.data.responseMessage;
            }
          })
          .catch((error) => {
            console.log("error :" + error);
            this.snackbar = true;
            this.text = "Something went wrong, try again!"
          });
      } else {
        this.$globalData.show = false;
        this.disabled = false;
        console.log("not cache");
      }
    },
    goback(){
      this.$router.go(-1)
    },

    matchingPasswords() {
      if (this.password === this.confirm_password) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
  },
};
