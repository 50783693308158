import api from "./api.js";

export default {
  data: () => ({
    search: "",
    text: "",
    data: [],
    items: [],
    cat_list: [],
    brand_list: [],
    brands: [],
    datalaoded: 0,
    categories: [],
    dialog: false,
    id: null,
    name: null,
    // price: null,
    description: null,
    category: null,
    brand: null,
    picture: null,
    label: "",
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    tokendialog: false,
    token: null,
    input: false,
    newpic: null,
    cats: [],
    myCurrentData: [],
    loading: true,
    json_fields: {
      Id: "id",
      Name: "name",
      // 'Price': 'price',
      Description: "description",
      Category: "cat_id",
      Brand: "brand_id",
    },
    headers: [
      {
        text: "Product",
        align: "center",
        value: "picture",
      },
      {
        text: "ID",
        align: "center",
        value: "id",
      },
      {
        text: "Name",
        align: "center",
        value: "name",
      },
      {
        text: "Description",
        align: "center",
        value: "description",
      },
      {
        text: "Category",
        align: "center",
        value: "cat_id",
      },
      {
        text: "Brand",
        align: "center",
        value: "brand_id",
      },
      {
        text: "Label",
        align: "center",
        value: "label",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),
  mounted() {
    this.datalaoded = 0;
    api
      .getproducts()
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.data);
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.datalaoded++;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getbrands()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            this.brand_list.push(element);
          });
          this.datalaoded++;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getcategories()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            this.cat_list.push(element);
          });
          this.datalaoded++;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  watch: {
    datalaoded(newVal) {
      console.log(this.datalaoded);
      if (newVal == 3) this.loading = false;
    },
  },

  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    showDialog(item) {
      if (item == null) {
        this.selected = 0;
        this.dialog = true;
        this.id = null;
        this.name = null;
        // this.price = null;
        this.description = null;
        this.picture = null;
        this.category = null;
        this.brand = null;
        this.mobile = null;
        this.disable = false;
        this.label = null;
      } else {
        console.log(item.cat_id);
        this.selected = 1;
        this.selected_item = item;
        this.dialog = true;
        this.id = item.id;
        this.name = item.name;
        // this.price = item.price;
        this.description = item.description;
        this.picture = item.picture;
        this.category = item.cat_id;
        this.brand = parseInt(item.brand_id);
        this.mobile = item.mobile;
        this.disable = false;
        this.label = item.label;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.selected == 0) {
          let formData = new FormData();
          formData.append("id", this.id);
          formData.append("name", this.name);
          // formData.append("price", this.price);
          formData.append("description", this.description);
          formData.append("picture", this.picture);
          formData.append("category", this.category);
          formData.append("brand", this.brand);
          formData.append("selected", this.selected);
          formData.append("label", this.label);
          this.disable = true;
          api
            .save(formData)
            .then((res) => {
              this.dialog = false;
              if (res.data.responseCode === 1) {
                this.$router.go();
                this.items.push({
                  id: res.data.data.id,
                  name: res.data.data.name,
                  // price: res.data.data.price,
                  description: res.data.data.description,
                  picture: res.data.data.picture,
                  cat_id: res.data.data.cat_id,
                  brand_id: res.data.data.brand_id,
                  out_of_stock: res.data.data.out_of_stock,
                });
                this.snackbar = true;
                this.text = "Product added successfully!";
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, please!";
              }
            })
            .catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;
              console.log(error);
            });
        } else {
          let formData2 = new FormData();
          formData2.append("id", this.id);
          formData2.append("name", this.name);
          // formData2.append("price", this.price);
          formData2.append("description", this.description);
          formData2.append("category", this.category);
          formData2.append("brand", this.brand);
          formData2.append("selected", this.selected);
          formData2.append("label", this.label);

          if (this.input == true) {
            formData2.append("picture", this.newpic);
          } else {
            formData2.append("picture", null);
          }
          console.log(this.brand);
          console.log(this.category);
          this.disable = true;
          api
            .save(formData2)
            .then((res) => {
              this.dialog = false;
              console.log("responseCode=" + res.data.responseCode);
              if (res.data.responseCode === 1) {
                this.$router.go();
                var index = this.items.indexOf(this.selected_item);
                this.items[index].name = this.name;
                // this.items[index].price = this.price;
                this.items[index].description = this.description;
                this.items[index].picture = this.newpic;
                this.items[index].category = this.cat_id;
                this.items[index].brand = this.brand_id;
                this.snackbar = true;
                this.items[index].out_of_stock = this.out_of_stock;
                this.text = "Product updated successfully!";
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, Please!";
              }
            })
            .catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;
            });
        }
      }
    },

    showinput() {
      this.input = true;
      document.getElementById("img").style.display = "none";
      document.getElementById("img-icon").style.display = "none";
    },

    deleteDialog(item) {
      this.selected_item = item;
      this.deletedialog = true;
    },

    confirmOnDelete() {
      api
        .delete(this.selected_item.id)
        .then((res) => {
          this.deletedialog = false;
          if (res.data.responseCode === 1) {
            // this.$router.go();
            var index = this.items.indexOf(this.selected_item);
            this.$delete(this.items, index);
            this.snackbar = true;
            this.text = "Product deleted!";
          } else {
            console.log(res.data.responseMessage);
            this.snackbar = true;
            this.text = "Try again!";
          }
        })
        .catch((error) => {
          // console.log(error);
          this.deletedialog = false;
          this.snackbar = true;
          this.text = error;
        });
    },
  },
};
