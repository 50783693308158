import axios from "@/plugins/http.service.js";

export default {
  verify(id, token) {
    var data = {
        'id': id,
        'token': token, 
    };
    // console.log("data= "+ data.email + " " + data.password);
    return axios.post("customer/verify", data);
  },
};
