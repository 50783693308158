import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    id: null,
    email: null,
    admin: true,
  },
  mutations: {
    savedata(state, { id, email, admin }) {
      state.id = id;
      state.email = email;
      state.admin = admin;
      localStorage.setItem("admin_id", id);
      localStorage.setItem("admin_email", email);
      localStorage.setItem("admin", admin);
    },
  },
  actions: {
    async login({ commit }, { id, email, admin }) {
      commit("savedata", { id, email, admin });
    },
  },
  modules: {},
});
