import axios from "axios";


let API_ENDPOINT = "https://catalog-coffeedesk.ae/api/web/";   // demo
// let API_ENDPOINT = "http://localhost:8084/api/web/";  //local
let config = {
  baseURL: `${API_ENDPOINT}`,
};

const httpClient = axios.create(config);

export default httpClient;
