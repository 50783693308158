<template>
  <div id="parent-container">
    <v-progress-linear
      :active="$globalData.show"
      slot="progress"
      color="#ff8593"
      indeterminate
    ></v-progress-linear>
    <div class="parent">
      <v-app id="inspire">
        <!-- <v-navigation-drawer
          v-model="drawer"
          color="#f2f4f5"
          app
          mobile-breakpoint="1024"
          floating
          height="100vh"
          style="box-shadow: rgb(2 2 2 / 18%) 2px -1px 6px 0px;"
        >
          <v-list style="top: 50px" class="pa-0">
            <v-list-item>
              <v-list-item-icon>
                <div class="mainlogocontainer">
                  <img
                    style="
                          border-style: none;
                          max-height: 200%;
                          max-width: 200%;
                          vertical-align: middle;
                          object-fit: cover;
                        "
                    alt="Vue logo"
                    class="logo"
                    src="../assets/images/coffeedesk.png"
                  />
                </div>
              </v-list-item-icon>
            </v-list-item>
            <div :key="index" v-for="(item, index) in items">
              <v-list-item
                exact-active-class="selectedrouter"
                v-if="!item.children"
                :key="item.title"
                :to="item.to"
                class="sidebarmenu"
                justify-center
                @click="drawer = false"
              >
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-title class="alleft">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </div>
            <v-list-item class="logout-btn" @click="logout">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer> -->
        <div class="header-menu">
          <!-- <v-col align="end">
            <div
              @click.stop="drawer = !drawer"
              class="bold"
              style="cursor: pointer"
            >
              My account
            </div>
          </v-col> -->
          <v-col>
            <v-row style="height: 65px">
              <v-col cols="3" id="logo" style="padding: 0;">
                <img
                  src="@/assets/images/coffeedesk.png"
                  width="250px"
                  @click="gohome"
                  style="cursor: pointer"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="7" style="padding: 0" id="col-7">
                <div class="menu">
                  <div class="menu-container">
                    <v-hover v-slot="{ hover }">
                      <v-list-item>
                        <v-list-item-title> Brands</v-list-item-title>
                        <div v-if="hover">
                          <div class="white-list">
                            <div v-for="(item, i) in brand_list" :key="i">
                              <router-link :to="'/products/brands/' + item">
                                <v-list-item
                                  @click="drawer = false"
                                  exact-active-class=""
                                  v-if="!item.children"
                                  :key="item.title"
                                  :to="item.to"
                                  justify-center
                                  column
                                  align-center
                                  inactive
                                >
                                  <v-list-item-title class="alleft">{{
                                    item
                                  }}</v-list-item-title>
                                </v-list-item></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </v-hover>

                    <div :key="index" v-for="(item, index) in header_cat">
                      <v-hover v-slot="{ hover }">
                        <v-list-item
                          exact-active-class=""
                          v-if="!item.children"
                          :key="item.title"
                          :to="item.to"
                          style="position: relative"
                          justify-center
                          class="headerlist"
                        >
                          <router-link :to="'/category/' + item.name"
                            ><v-list-item-title class="alleft">{{
                              item.name
                            }}</v-list-item-title></router-link
                          >
                          <div v-if="hover">
                            <div class="white-list">
                              <div v-for="(item_cat, i) in cat_list" :key="i">
                                <div v-if="item_cat.parent == item.id">
                                  <router-link
                                    :to="'/products/category/' + item_cat.name"
                                  >
                                    <v-list-item
                                      exact-active-class=""
                                      v-if="!item.children"
                                      :key="item.title"
                                      :to="item.to"
                                      justify-center
                                      column
                                      align-center
                                      inactive
                                    >
                                      <v-list-item-title class="alleft">{{
                                        item_cat.name
                                      }}</v-list-item-title>
                                    </v-list-item></router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-list-item>
                      </v-hover>
                    </div>
                    <p @click="gotobasket" style="cursor: pointer">
                      Basket ({{ $globalData.basket }})
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-app>
      <div class="home-container">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>

    <div class="footer">
      <div class="footer-container">
        <div class="list">
          <v-list-item-title> Brands </v-list-item-title>
          <div :key="index" v-for="(item, index) in header_cat">
            <v-list-item
              exact-active-class=""
              v-if="!item.children"
              :key="item.title"
              :to="item.to"
              class=""
              justify-center
            >
              <v-list-item-title class="alleft">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </div>
        </div>
        <div class="settings">
          <div :key="index" v-for="(item, index) in settings">
            <v-list-item
              exact-active-class=""
              v-if="!item.children"
              :key="item.title"
              :to="item.to"
              class=""
              justify-center
            >
              <div v-if="index == 0">
                <div class="icon">
                  <v-list-item-action>
                    <img src="@/assets/images/email.svg"
                  /></v-list-item-action>
                </div>
              </div>
              <div v-else>
                <div class="icon">
                  <v-list-item-action>
                    <img src="@/assets/images/phone.svg"
                  /></v-list-item-action>
                </div>
              </div>
              <v-list-item-title class="alleft">{{ item }}</v-list-item-title>
            </v-list-item>
          </div>
        </div>
        <div class="cc">
          © 2022 Coffeedesk.com - By using the CoffeeDesk service you accept its
          terms and conditions.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/http.service.js";

export default {
  name: "customerlayout",
  data: () => ({
    header_cat: [],
    cat_list: [],
    settings: [],
    brand_list: [],
    basket: null,
    show: null,
    drawer: false,
    items: [],
  }),

  mounted() {
    this.items = [
      {
        title: "My Orders",
        icon: "mdi-mailbox",
        to: "/my-orders",
      },
      {
        title: "Change Password",
        icon: "mdi-lock",
        to: "/change-password",
      },
    ];
    this.basket = localStorage.getItem("basket");
    axios
      .post("category/get-categories")
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            this.cat_list.push({
              id: element.id,
              name: element.name,
              parent: element.parent_id,
            });
            if (element.parent_id == null) {
              this.header_cat.push({
                id: element.id,
                name: element.name,
              });
            }
          });
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("settings/get-settings")
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            this.settings.push(element.setting);
          });
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("brand/get-brands")
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            this.brand_list.push(element.name);
          });
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    gotobasket() {
      this.$router.push({ name: "Basket" });
    },
    gohome() {
      this.$router.push({ name: "Home" });
    },
    open_menu() {
      console.log("open");
    },

    logout: function() {
      localStorage.clear();
      this.$globalData.basket = 0;
      this.$globalData.basket_items = [];
      this.$router.push({ name: "Login" });
    },
    // update(){
    //   this.basket= localStorage.getItem('basket')
    //   console.log(this.basket)
    // }
  },
};
</script>
<style scoped>
* {
  font-family: Montserrat, sans-serif !important;
}

#parent-container .mainlogocontainer {
  top: 220%;
  right: unset;
  left: 45%;
}

#parent-container .mainlogocontainer img {
  width: 100px;
}
.footer .v-list-item__title {
  color: #fff;
  font-size: 14px;
}
.home-container {
  padding: 85px 0 50px;
}

#parent-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.bold {
  font-weight: bold;
}
.parent {
  width: 1240px;
  margin: 0 auto;
  max-width: 90%;
  margin-top: 30px !important;
}
.header-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.menu {
  background-color: var(--primary);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1;
}
.menu-container {
  max-width: 100%;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.menu-container p {
  font: normal normal bold 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0 !important;
  margin: 0 16px;
  line-height: 1;
}
#inspire {
  height: 50px;
}

.header-menu .v-list-item {
  /* padding: 0 !important; */
  flex: 0 !important;
  height: 70px;
  /* width: 100px; */
}
.footer {
  background-color: var(--secondary);
  min-height: 200px;
  width: 100%;
  padding-top: 35px;
}

.footer-container {
  margin: 0 auto;
  width: 1240px;
  display: flex;
  flex-direction: row;
  max-width: 90%;
}
.list {
  margin-right: 100px;
}
.header-menu .list .v-list-item,
.footer .list .v-list-item {
  padding: 0 !important;
  min-height: 30px !important;
  height: unset !important;
}

.header-menu .list .v-list-item__title,
.footer .list .v-list-item__title,
.settings .v-list-item__title,
.cc {
  font-size: 14px;
  font-weight: 300 !important;
  padding: 0px !important;
  min-height: 30px;
  color: white;
}

.settings .v-list-item {
  height: 30px !important;
  min-height: 35px !important;
}

.settings .v-list-item__title {
  min-height: unset !important;
}

.settings .v-list-item .v-list-item__action {
  margin-right: 10px !important;
}
.settings {
  margin-right: 100px;
}
.cc {
  margin: auto;
}
.header-menu .v-list-item__title,
.footer .v-list-item__title {
  font: normal normal bold 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}
.white-list {
  box-shadow: -3px 3px 12px 0px #0000002c;
  background-color: white !important;
  z-index: 1;
  width: 250px;
  height: auto;
  display: flex;
  flex-direction: column !important;
  /* display: none; */
  position: absolute;
  top: 71px;
  right: 0%;
  left: -0;
}
.white-list .v-list-item {
  height: 30px !important;
  text-decoration: none;
  min-height: unset !important;
  padding: 5px 16px !important;
}
/* .white-list .v-list-item:first-child{
  margin-top: 20px;
} */
.white-list .v-list-item__title {
  color: var(--secondary);
  font-weight: 300;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}
.white-list a {
  text-decoration: none !important;
}
@media (max-width: 1240px) {
  #col-7 {
    max-width: 65% !important;
    flex-basis: 65% !important;
  }
}
@media (max-width: 1023px) {
  .menu-container div {
    display: none;
  }
  #col-7 {
    max-width: 30% !important;
    flex-basis: 30% !important;
  }
}
@media (max-width: 990px) {
  .footer-container {
    flex-wrap: wrap;
  }
  .footer {
    height: fit-content;
  }
  .footer-container .list,
  .footer-container .settings {
    margin: 0 !important;
    flex-basis: 50%;
  }
  .header-menu .v-list-item,
  .footer .v-list-item {
    padding: 0;
  }
  .cc {
    text-align: center;
    margin: 10px 0;
  }
}
@media (max-width: 450px) {
  #logo {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  #col-7 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    height: 50px;
  }
  .home-container {
    padding: 100px 0;
  }
  .footer-container .list,
  .footer-container .settings {
    margin: auto !important;
    flex-basis: 90%;
  }
}
</style>
