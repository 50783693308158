

import api from "./api.js";

export default {
  data: () => ({
    email: "",
    password: "",
    valid: true,
    showpass: false,
    emailRules: [v => /.+@.+/.test(v) || 'Invalid Email address'],
    passRules: [v => !!v || 'Password is required'],
    warn: false,
    text: '',
  }),
  mounted() {
    this.$globalData.show = false;

    // localStorage.clear();
    // this.$globalData.basket = 0;
    // this.$globalData.basket_items = [];
  },
  methods: {
    loginto() {
      if (this.$refs.form.validate()) {
        api
          .login(this.email, this.password)
          .then((res) => {
            console.log(res.data.responseCode);
            if (res.data.responseCode === 1) {

              // res.data.data.forEach((element) => {
              var id = res.data.data.id;
              var email = res.data.data.email
              if (res.data.data.first_name != null) {
                var admin = false;
                this.$store.dispatch("login", { id, email, admin });
                this.$router.push({ name: "Home" });
                localStorage.setItem("customer_fname", res.data.data.first_name);
                localStorage.setItem("customer_lname", res.data.data.last_name);
                console.log(localStorage.getItem("customer_fname"))
                console.log(admin);
              } else {
                admin = true;
                this.$store.dispatch("login", { id, email, admin });
                this.$router.push({ name: "Customers" });
              }
              // })
            } else {
              this.warn = true;
              console.log(res.data.responseMessage);
              this.text = res.data.responseMessage;
            }
          })
          .catch((error) => {
            this.warn = true;
            console.log("error :" + error);
          });
      } else {
        console.log("not cache");
      }
    },
  },
};