import api from "./api.js";

export default {
  data: () => ({
    search: "",
    text: "",
    data: [],
    items: [],
    options: [],
    // admin: true,
    // menu: false,
    dialog: false,
    id: null,
    featured: null,
    picture: null,
    selected: 0,
    selected_item: null,
    deletedialog: false,
    snackbar: false,
    timeout: 2000,
    disable: false,
    loading: true,
    myCurrentData: [],
    json_fields: {
      'Id': 'id',
      'Featured': 'featured',
      'picture': 'picture',
  },
    headers: [
        {
        text: "Picture",
        align: "center",
        value: "picture",
      },
      {
        text: "ID",
        align: "center",
        value: "id",
      },
      {
        text: "Featured",
        align: "center",
        value: "featured",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
      },
    ],
  }),

  mounted() {
    // this.admin = localStorage.getItem("admin");
    // console.log(this.admin);
    this.options = ['Yes', 'No'];
    api
      .getads()
      .then((res) => {
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.loading = false;
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
  },
    showDialog(item) {
      if (item == null) {
        this.selected = 0;
        this.dialog = true;
        this.id = null;
        this.featured = null;
        this.picture= null;
        this.disable = false;
      } else {
        this.selected = 1;
        this.selected_item = item;
        this.dialog = true;
        this.id = item.id;
        this.featured = item.featured;
        this.disable = false;
      }
    },

    save() {
         let formData = new FormData();
          formData.append("id", this.id);
          formData.append("featured", this.featured);
          formData.append("picture", this.picture);
          formData.append("selected", this.selected);

      if (this.$refs.form.validate()) {
        if (this.selected == 0) {
          this.disable = true;
          api.save(formData)
            .then((res) => {

              this.dialog = false;
              if (res.data.responseCode === 1) {
                // this.$router.go();
                this.items.push({
                  id: res.data.data.id,
                  featured: res.data.data.featured,
                  picture: res.data.data.picture,
                });
                this.snackbar = true;
                this.text = "Ad picture added successfully!"
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;
              console.log(error);

            })

        } else {
          this.disable = true;
          api.save(formData)
            .then((res) => {
              this.dialog = false;
              console.log("responseCode=" + res.data.responseCode);
              if (res.data.responseCode === 1) {
                // this.$router.go();
                var index = this.items.indexOf(this.selected_item);
                this.items[index].featured = this.featured;
                this.items[index].picture = this.picture;
                this.snackbar = true;
                this.text = "Ad picture updated successfully!";
                // this.disable = false;
              } else {
                console.log(res.data.responseMessage);
                this.snackbar = true;
                this.text = "Try again, Please!"

              }
            }).catch((error) => {
              this.dialog = false;
              this.snackbar = true;
              this.text = error;

            })

        }
      }
    },

    deleteDialog(item) {
      this.selected_item = item;
      this.deletedialog = true;
    },

    confirmOnDelete() {
      api.delete(this.selected_item.id).then((res) => {
        this.deletedialog = false;
        this.loading = false;
        if (res.data.responseCode === 1) {
          var index = this.items.indexOf(this.selected_item);
          this.$delete(this.items, index);
          this.snackbar = true;
          this.text = "Ad picture deleted!"
        } else {
          this.snackbar = true;
          this.text = "Try again!"
        }
      }).catch((error) => {
        this.deletedialog = false;
        this.snackbar = true;
        this.text = error
      })
    },
  },
};
