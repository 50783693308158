import api from "./api.js";

export default {
    data: () => ({
        search: "",
        text: "",
        data: [],
        items: [],
        datalaoded: 0,
        id: null,
        name: null,
        price: null,
        total: 0,
        description: null,
        category: null,
        loading: true,
        selected_item: null,
        headers: [
            {
                text: "ID",
                align: "center",
                value: "id"
            },
            {
                text: "Product",
                align: "center",
                value: "product",
            },
            {
                text: "Quantity",
                align: "center",
                value: "quantity",
            },
            {
                text: "Total",
                align: "center",
                value: "total",
            },
        ],

    }),
    mounted() {
        api
            .getorderlines(this.$route.params.order)
            .then((res) => {
                if (res.data.responseCode === 1) {
                    this.items = res.data.data;
                    this.loading = false;
                } else {
                    console.log(res.data.responseMessage);
                    if(res.data.responseMessage == 'Invalid Data.'){
                        this.loading = false;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    methods :{
        goback() {
            this.$router.go(-1)
          }
    }
};
