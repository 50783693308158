import axios from "@/plugins/http.service.js";

export default {
  changePassword(id, token, password) {
    var data = {
        'id': id,
        'token': token,
        'password': password, 
    };
    return axios.post("customer/change-password", data);
  },
};
