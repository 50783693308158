import axios from "@/plugins/http.service.js";

export default {
  sendEmail(email) {
    var data = {
        'email': email
    };
    return axios.post("customer/send-email", data);
  },
};
