import api from "./api.js";

export default {
  data: () => ({
    id: null,
    enabled: null,
    name: null,
    price: null,
    loading: true,
    search: "",
    items: [],
    headers: [
      {
        text: "Product",
        align: "center",
        value: "name",
      },
      {
        text: "Quantity",
        align: "center",
        value: "quantity",
      },
      {
        text: "Total",
        align: "center",
        value: "total",
      },
      {
        text: "Date",
        align: "center",
        value: "created_date",
      },
    ],
  }),
  mounted() {
    this.id = localStorage.getItem("admin_id");

    api
      .getOrders(this.id)
      .then((res) => {
        
        if (res.data.responseCode === 1) {
          console.log(res.data.responseCode);
          this.items = res.data.data;
          this.loading = false;
        } else {
          this.items = [];
          this.nodata = true
          this.loading = false;
        }
      })
      .catch((error) => {
        console.log(error);
        this.nodata = true
      });
  },
  watch: {
    enabled (slot) {
      if (slot === 'no-data') {
        this.items = []
        console.log('watchrf')
      } 
      // console.log('watchrf')
    },
  },
  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    isEnabled (slot) {
      return this.enabled === slot
    },
  },


};
