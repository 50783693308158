import api from "./api.js";

export default {
  data: () => ({
    email: "",
    disabled: false,
    valid: true,
    showpass: false,
    showconfirm: false,
    text: '',
    snackbar: false,
    timeout: 3000,
    emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
  }),
  mounted() {
    this.$globalData.show = false;
  },
  methods: {
    sendEmail() {
        this.$globalData.show = true;
        this.disabled = true;
      if (this.$refs.form.validate()) {
        api
          .sendEmail(this.email)
          .then((res) => {
            if (res.data.responseCode === 1) {
                this.$globalData.show = false;
                this.disabled = false;
                this.snackbar = true;
                this.text = "Please check your email!"
            }
          })
          .catch((error) => {
            console.log("error :" + error);
            this.snackbar = true;
            this.text = "Something went wrong, try again!"
          });
      }
    },

    goback(){
      this.$router.go(-1)
    },
    matchingPasswords() {
      if (this.password === this.confirm_password) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
  },
};
