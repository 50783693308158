import axios from "@/plugins/http.service.js";

export default {
  login(email, pass) {
    var data = {
      'email': email,
      'password': pass
    };
    // console.log("data= "+ data.email + " " + data.password);
    return axios.post("admin/login", data);
  },
};
